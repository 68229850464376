import React, { useState, useEffect } from 'react'
import styles from './App.module.css';
import configData from './config.json';

import type { IPaginationRequestDTO, IPaginationResult, IRequestOptions } from './types/API';
import type { IPost } from './types/Post';
import type { ISocialMediaDTO } from './types/SocialMedia';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PostCard from './components/PostCard';
import SocialMedia from './components/SocialMedia';
import Footer from './components/Footer';

const settings: IRequestOptions = {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
};

const defaultPosts: IPaginationResult = {
    totalCount: 0,
    items: []
};
const defaultSocialMedias: ISocialMediaDTO[] = [];

const App: React.FC = () => {
    const [posts, setPosts]: [IPaginationResult, (posts: IPaginationResult) => void] = useState(defaultPosts);
    const [socialMedias, setSocialMedias]: [ISocialMediaDTO[], (posts: ISocialMediaDTO[]) => void] = useState(defaultSocialMedias);

    const [loadingPosts, setLoadingPosts]: [boolean, (loading: boolean) => void] = useState<boolean>(true);
    const [loadingMorePosts, setloadingMorePosts]: [boolean, (loading: boolean) => void] = useState<boolean>(false);
    const [loadingSocialMedias, setLoadingSocialMedias]: [boolean, (loading: boolean) => void] = useState<boolean>(true);

    const [errorPosts, setErrorPosts]: [string, (error: string) => void] = useState("");
    const [errorSocialMedias, setErrorSocialMedias]: [string, (error: string) => void] = useState("");


    const getPosts = async (interval: IPaginationRequestDTO, settings: IRequestOptions): Promise<void> => {
        settings.body = JSON.stringify(interval);
        await fetch(configData.POSTS_URL, settings)
            .then(response => {
                if(response.statusText === "OK" && response.status >= 200 && response.status < 300)
                    return response.json();
                else
                    throw new Error("Server can't be reached.");
            })
            .then(data => {
                let newData = {
                    totalCount: data.totalCount,
                    items: posts.items.concat(data.items)
                };
                setPosts(newData);
            })
            .catch(error => setErrorPosts(error))
            .finally(() => setLoadingPosts(false));
    }

    const getSocialMedias = async (): Promise<void> => {
        return await fetch(configData.SOCIALMEDIA_URL)
            .then(response => {
                if(response.statusText === "OK" && response.status >= 200 && response.status < 300)
                    return response.json();
                else
                    throw new Error("Server can't be reached.");
            })
            .then(data => setSocialMedias(data))
            .catch(error => setErrorSocialMedias(error))
            .finally(() => setLoadingSocialMedias(false));
    }

    useEffect(() => {
        getSocialMedias();
        getPosts({
                search: "",
                pos: 0,
                count: 3,
                sort: "",
                sortAscending: false
            },
            settings);
    }, []);

    const handleLoadMore = async () => {
        if(loadingMorePosts)
            return;

        setloadingMorePosts(true);
        await getPosts({
                search: "",
                pos: posts.items.length,
                count: 3,
                sort: "",
                sortAscending: false
            }, settings);

        setloadingMorePosts(false);
    }

    if(loadingPosts || loadingSocialMedias)
    {
        return (
            <div className={styles.centerContainer}>
                <CircularProgress />
            </div>
        );
    }

    if(errorPosts || errorSocialMedias)
    {
        return (
            <div className={styles.centerContainer}>
                <Typography variant="h6" color="error">
                    Error occured while contacting server
                </Typography>
            </div>
        );
    }

    let web = socialMedias.find(obj => obj.id === "Web");
    return (
        <div className={styles.container}>
            <div className={styles.mainContainer}>
                {
                    web &&
                    <div className={styles.logoWrapper}>
                        <div className={styles.logoContainer}>
                            <SocialMedia key="MaethrilLogo" id={web.id} url={web.url as string} />
                        </div>
                    </div>
                }
                <div className={styles.socialMediaContainer}>
                    {
                        socialMedias.map(socialMedia =>
                            socialMedia.url &&
                        <div key={socialMedia.id} className={styles.socialMediaWrapper}>
                            <SocialMedia id={socialMedia.id} url={socialMedia.url as string} />
                        </div>
                    )}
                </div>
                <div>
                {
                    posts.items.map((post, i) =>
                        <PostCard key={i} id={post.id} title={post.title} description={post.description} url={post.url} />
                )}
                </div>
                {
                    posts.items.length < posts.totalCount &&
                        <div className={styles.loadMoreContainer}>
                            {loadingMorePosts ? <CircularProgress /> : <Button color="primary" size="medium" onClick={handleLoadMore}>Load more</Button>}
                        </div>
                }
            </div>
            <Footer />
        </div>

    );
}

export default App;
