import React from 'react';
import type { IPost } from '../types/Post';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import styles from './PostCard.module.css';

const PostCard: React.FC<IPost> = ({id, title, url, description}) => {
    function handleClick() : void {
        window.location.href = url;
    }

    return (
        <Card onClick={handleClick} className={styles.postCard}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body2" component="p">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default PostCard;
