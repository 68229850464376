import React from 'react';
import styles from './Footer.module.css';

const Footer: React.FC = () => {

    return (
        <div className={styles.container}>
            <div className={styles.linksContainer}>
                <a href="https://blog.maethril.net/terms-of-service">Terms Of Service</a>
                <a href="https://blog.maethril.net/privacy-notice">Privacy Notice</a>
            </div>
            <span>© {new Date().getFullYear()} Maethril</span>
        </div>
    );
}

export default Footer;
